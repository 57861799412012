<template>
  <section class="main-top-info-block">
    <swiper
      :slides-per-view="1"
      class="main-top-info-slider"
    >
      <swiper-slide
        v-for="(item, idx) in sliderItems"
        :key="`main-top-info-slide-${idx + 1}`"
        class="main-top-info-slide"
      >
        <div class="main-top-info-wrapper">
          <NuxtImg
            :src="String(item.image)"
            :alt="item.title"
            class="block-bg-image main-top-info-block-bg"
          />
          <div class="container">
            <div class="main-top-info-inner">
              <div class="main-top-info-card">
                <div class="main-top-info-card-top">
                  <p v-if="item.title" v-html="item.title" class="main-top-info-card-title"/>
                  <p
                    v-if="item.text"
                    v-html="item.text"
                    class="main-top-info-card-description"
                  >
                  </p>
                </div>
                <div class="main-top-info-card-bottom">
                  <nuxt-link
                    class="btn primary outlined main-top-info-card-link"
                    :to="item.link"
                  >
                    {{ item.link_text }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script setup lang="ts">
import {Swiper, SwiperSlide} from "swiper/vue";
import type {IBlockDefaultProps} from "#sitis/internal/models/common";
import type {ComputedRef} from "vue";

const props = defineProps<IBlockDefaultProps>();

const sliderItems: ComputedRef<any[]> = computed(() => {
  const values = props.values;
  return [values]
});
</script>

<style lang="scss">
.main-top-info-block {
  min-width: 0;
  width: 100%;
  height: calc(100vh - 230px);
  position: relative;
  overflow: hidden;

  .swiper {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    float: left;
    padding-top: 46%;
  }
}

.main-top-info-block-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  z-index: -1;
}

.main-top-info-slider {
  height: 100%;
}

.main-top-info-slide {
  height: auto;
}

.main-top-info-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-top-info-inner {
  padding: 65px 0;
  display: flex;
  flex-direction: column;
}

.main-top-info-card {
  align-self: flex-end;
  background: rgb(255 255 255 / 60%);
  padding: 22px 35px 38px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.main-top-info-card-top {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-top-info-card-title {
  font-size: 45px;
  line-height: 58px;
  color: #509646;
}

.main-top-info-card-description {
  font-size: 16px;
  line-height: normal;
}

.main-top-info-card-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-top-info-card-link {
  align-self: flex-end;
  padding: 13px 14px;
  font-size: 19px;
  line-height: 31px;
  font-weight: 700;
  height: 100%;
}

@media (max-width: 1023px) {
  .main-top-info-block {
    min-height: 200px;
    height: initial;
  }

  .main-top-info-wrapper {
    .container {
      padding: 0;
    }
  }

  .main-top-info-inner {
    padding: 24px 7px;
  }

  .main-top-info-card {
    background: rgb(255 255 255 / 60%);
    padding: 6px 5px;
    gap: 49px;
  }

  .main-top-info-card-top {
    gap: 5px;
  }

  .main-top-info-card-title {
    font-size: 18px;
    line-height: 23px;
  }

  .main-top-info-card-description {
    font-size: 12px;
  }

  .main-top-info-card-link {
    align-self: flex-start;
    padding: 9px 13px;
    font-size: 16px;
    line-height: 16px;
  }
}
</style>
